import React, { useEffect, useState } from "react";
import Loader from "react-loaders";
import AnimatedLetter from "../Animated Letters/AnimatedLetters.js";
import "./Projects.scss";
import firstWebsiteImg from "../../assets/images/my-first-website.png";
import ecommerWebsite from "../../assets/images/NextJS website.png";
import meetUpImg from "../../assets/images/NextJS website meet-up.png";
import managementImg from "../../assets/images/ReactJs and Firebase Management List.png";

const Portfolio = () => {
  const [letterClass, setLetterClass] = useState("text-animate");

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLetterClass("text-animate-hover");
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, []);

  function handleClick(website) {
    switch (website) {
      case "mars-daycare":
        window.open("https://mars-daycare-center.netlify.app/");
        break;
      case "e-commerce-car":
        window.open("https://e-commmerce-car-web-site.vercel.app/");
        break;
      case "meetup-planner":
        window.open("https://meetup-plan-ner.vercel.app/");
        break;
      case "item-management":
        window.open("https://item-management.vercel.app/");
        break;
      default:
        break;
    }
  }

  return (
    <>
      <div className="container portfolio-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetter
              letterClass={letterClass}
              strArray={["P", "r", "o", "j", "e", "c", "t", "s"]}
              idx={15}
            />
          </h1>
          <p>
            This are the website that I was able to develop and deploy online
            throughout my Journey for the Web development
          </p>
        </div>
        <div className="project-content">
          <ul>
            <li>
              <img src={firstWebsiteImg} alt="Website" className="websiteImg" />
              <span>
                <button
                  onClick={() => handleClick("mars-daycare")}
                  className="flat-button"
                >
                  Click here to visit the Website
                </button>

                <h2>My Capstone Project</h2>
              </span>
            </li>
            <li>
              <img src={ecommerWebsite} alt="Website" className="websiteImg" />
              <span>
                <button
                  onClick={() => handleClick("e-commerce-car")}
                  className="flat-button"
                >
                  Click here to visit the Website
                </button>
                <h2>NextJS E-commerce Project Website</h2>
              </span>
            </li>
            <li>
              <img src={meetUpImg} alt="Website" className="websiteImg" />
              <span>
                <button
                  onClick={() => handleClick("meetup-planner")}
                  className="flat-button"
                >
                  Click here to visit the Website
                </button>
                <h2>Meet up Planner Using NextJS</h2>
              </span>
            </li>
            <li>
              <img src={managementImg} alt="Website" className="websiteImg" />
              <span>
                <button
                  onClick={() => handleClick("item-management")}
                  className="flat-button"
                >
                  Click here to visit the Website
                </button>
                <h2>Item Management Using React JS and Firebase</h2>
              </span>
            </li>
          </ul>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  );
};

export default Portfolio;
